import React, { useContext } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CSSTransition } from "react-transition-group"
import { settingsContext } from "@context/provider"
import LinkedIn from "@svg/social/linkedin.svg"
import Exit from "@svg/ui/exit.svg"

export const Detail = () => {
  const [settings, dispatchSettings] = useContext(settingsContext)
  const { personDetail } = settings

  const exitDetail = () => {
    dispatchSettings({ type: "SET_PERSON_DETAIL_MEMBER", payload: {} })
    dispatchSettings({ type: "SET_PERSON_DETAIL_DISPLAY", payload: false })

    dispatchSettings({ type: "SET_CURSOR", payload: { display: true, drag: false } })
  }

  return (
    <CSSTransition in={personDetail.display} timeout={800} classNames="member-detail" unmountOnExit>
      <div className="bg-purple md:transform-none md:mt-0">
        <div className="container relative">
          <div className="absolute inset-0 md:hidden">
            <div className="aspect-w-326 aspect-h-211 before:bg-white-dark after:bg-white-dark extend"></div>
          </div>
          <div className="relative">
            <div className="md:absolute inset-x-0 w-full z-20 overflow-hidden">
              <div className="member-detail-bg md:bg-gray-medium relative md:h-177 md:aspect-w-667 md:aspect-h-502 lg:aspect-w-1436 lg:aspect-h-708">
                <div className="md:absolute inset-0">
                  <div className="relative w-full h-full flex flex-col md:flex-row">
                    <div className="relative md:absolute inset-0">
                      <div className="absolute top-0 right-0 z-10">
                        <div className="mt-6 mr-6 team-lg:mt-15 team-lg:mr-15">
                          <button
                            onClick={() => exitDetail()}
                            className="fill-current text-white sm:hover:text-gray-darkest transition-all duration-300 cursor-pointer flex justify-center items-center"
                            style={{ width: "40px", height: "40px" }}
                          >
                            <div style={{ height: "20px", width: "20px" }}>
                              <Exit />
                            </div>
                          </button>
                        </div>
                      </div>

                      {personDetail.display && (
                        <>
                          <div className="md:hidden">
                            <GatsbyImage
                              image={getImage(personDetail.member.profile.profile.localFile)}
                              alt={personDetail.member.title}
                              className="object-cover pointer-events-none"
                            />
                          </div>
                          <div className="hidden md:inline">
                            <GatsbyImage
                              objectFit="cover"
                              objectPosition="top left"
                              image={getImage(personDetail.member.profile.action.localFile)}
                              alt={personDetail.member.title}
                              className="member-detail-image w-full h-full pointer-events-none"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {personDetail.display && (
                      <div className="relative md:w-1/2 ml-auto md:grid grid-cols-6 py-10 md:py-0">
                        <div className="col-span-5 flex items-center">
                          <div className="text-white overflow-y-auto md:h-125">
                            <div data-aos="fade-up">
                              <h3 className="text-clamp-26-32">{personDetail.member.title}</h3>
                              <h4 className="font-semi text-clamp-18-22">{personDetail.member.profile.title}</h4>
                              {personDetail.member.profile?.linkedin && (
                                <a
                                  href={personDetail.member.profile.linkedin}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="block fill-current sm:hover:text-gray-darkest transition-all duration-300 mt-4"
                                  style={{ marginLeft: "-1px" }}
                                >
                                  <LinkedIn />
                                </a>
                              )}
                            </div>
                            <div data-aos="fade-up" data-aos-delay="100">
                              <h4 className="text-clamp-18-22 mt-clamp-7-12">What I do at JK:</h4>
                              <div
                                className="font-book text-18 mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: personDetail.member.profile.duty,
                                }}
                              />
                              <h4 className="text-clamp-18-22 mt-clamp-6-8">Why I love it here:</h4>
                              <div
                                className="font-book text-18 mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: personDetail.member.profile.love,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
