import { getRandomObjectsFromArray, splitText } from "@utils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Team } from "@components/Team"
import { Arrow } from "@components/UI"
import { usePageSetup } from "@components/usePageSetup"
import CertDiversity from "@svg/certificates/diversity.svg"
import DisabilityDobe from "@svg/certificates/dobe.svg"
import CertSmallBusiness from "@svg/certificates/small-business.svg"
import CertWBENC from "@svg/certificates/wbenc.svg"
import CertWomenOwned from "@svg/certificates/women-owned.svg"
import { graphql } from "gatsby"
import queryString from "query-string"
import React from "react"

const CulturePage = ({ data, location }) => {
  const {
    chrisHImage,
    laurenDImage,
    chrisMImage,
    bakedGoodsImage,
    chrisMOfficeImage,
    katieDImage,
    outsideImage,
    clutchImage,
  } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-teal-light" })
  const themeColorBefore = "before:bg-teal-light"
  const themeColorAfter = "after:bg-teal-light"
  const cultureImages = [
    { image: chrisHImage },
    { image: laurenDImage },
    { image: chrisMImage },
    { image: bakedGoodsImage },
    { image: chrisMOfficeImage },
    { image: katieDImage },
    { image: outsideImage },
  ]
  const randomizedCultureImage = getRandomObjectsFromArray(cultureImages, 1)[0].image

  return (
    <Layout>
      <Seo title="About Us" description="" />
      <section className={themeColor}>
        <Hero>
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "You need a powerful story.",

                  "text-gray-darkest": "We’re expert storytellers.",
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message>
            <p>
              <span className="font-bold">JK is a full-service creative agency</span> and women-owned small business
              with 37 years of experience in branding, employee engagement, and strategic execution.
            </p>
            <p>
              We partner with clients in the B2B, consumer, and internal comms spaces to help create and tell their most
              compelling stories—and connect with the people who matter most.
            </p>
          </Hero.Message>
        </Hero>
      </section>

      <div className="relative overflow-hidden">
        <div className="container">
          <div className={`aspect-w-1436 aspect-h-120 extend ${themeColorBefore} ${themeColorAfter}`}></div>
        </div>
      </div>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main>
        <section className="overflow-hidden pt-clamp-30-45">
          <div id="team" className="container anchor-offset">
            <div className="mx-auto lg:w-10/12">
              <div className="w-full">
                <h2 data-aos="stagger" className="text-title">
                  <span>Leaders.</span> <span>Creators.</span>
                  <div className="inline md:block"></div> <span className="text-purple-light">Game-changers.</span>
                </h2>
              </div>
            </div>
          </div>
          <Team queryParam={queryString.parse(location.search)} />
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-32-60 pb-clamp-22-40">
          <div className="container">
            <div className="mx-auto lg:w-10/12">
              <h3 data-aos="stagger" className="text-title">
                <span>Awards,</span> <span>certifications</span> <span>&amp;</span> <span>affiliations.</span>
              </h3>
              <div className="grid-cols-10 lg:grid mt-clamp-7-10">
                <div className="col-span-8" data-aos="fade-up">
                  <p>
                    JK is not only certified by the Women’s Business Enterprise National Council (WBENC) as a Women’s
                    Business Enterprise (WBE), but women also make up 50% of our leadership team and 59% of our overall
                    staff.
                  </p>
                  <p>
                    We are committed to integrating diversity, equity, and inclusion into every experience our clients
                    and partners have with us—including helping them promote DEI in their employee engagement efforts,
                    which is one of our core capabilities.
                  </p>
                  <p>
                    JK is also certified as a Small Business Enterprise (SBE), holds membership in the Diversity
                    Alliance for Science (DA4S), and has been named to the Chief Marketer 200 (CM200).
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-12 mt-clamp-12-17" data-aos="fade-up">
                <div className="w-30">
                  <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer" aria-label="Certified WBENC">
                    <CertWBENC />
                  </a>
                </div>
                <div className="w-28">
                  <a href="https://www.wbenc.org/" target="_blank" rel="noreferrer" aria-label="Certified Women Owned">
                    <CertWomenOwned />
                  </a>
                </div>
                <div className="w-27">
                  <a href="" target="_blank" rel="noreferrer" aria-label="Disability Owned In Business">
                    <DisabilityDobe />
                  </a>
                </div>
                <div className="w-30">
                  <CertSmallBusiness />
                </div>
                <div className="w-36">
                  <a
                    href="https://diversityallianceforscience.com/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Diversity Alliance For Science"
                  >
                    <CertDiversity />
                  </a>
                </div>
                <div className="w-20">
                  <a
                    href="https://clutch.co/women-owned-businesses/international-womens-day"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="International Womens Day"
                  >
                    <GatsbyImage image={getImage(clutchImage)} alt="Clutch Certificate" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* HUMANITY */}
        <section className="pt-clamp-18-34 pb-clamp-30-45 bg-teal-light">
          <div className="container">
            <div className="mx-auto lg:w-10/12">
              <div>
                <h3 data-aos="stagger" className="text-title mt-clamp-8-15">
                  <span>We're</span> <span>making</span> <span>the</span> <span>world</span>{" "}
                  <div className="inline md:block"></div>
                  <span className="text-white">better</span> <span className="text-white">than</span>{" "}
                  <span className="text-white">we</span> <span className="text-white">found</span>{" "}
                  <span className="text-white">it.</span>
                </h3>
              </div>
              <div className="flex flex-col-reverse grid-cols-2 mt-16 sm:grid lg:grid-cols-10 gap-x-4">
                <div className="mt-16 lg:col-span-4 sm:mt-0">
                  <h4 data-aos="fade-up" className="text-clamp-26-32">
                    We’re passionate about helping make our community stronger.
                  </h4>
                  <div data-aos="fade-up">
                    <p className="mt-clamp-9-17">
                      That’s why we started JK Gives Back, which gives every employee a day each year to do volunteer
                      work, and why we support charitable organizations that help the people (and animals) who need it
                      most.
                    </p>
                    <ul className="mt-10 lg:mt-14">
                      <li>Habitat for Humanity</li>
                      <li>Embrace Kids</li>
                      <li>Elijah’s Promise</li>
                      <li>Samaritan Homeless Interim Program</li>
                      <li>Tabby’s Place</li>
                      <li>United Way</li>
                      <li>Meals on Wheels</li>
                      <li>America’s Grow-a-Row</li>
                    </ul>
                  </div>
                </div>
                <div className="grid-cols-12 lg:col-span-6 lg:grid">
                  <div className="col-span-11 col-start-2 transform sm:translate-x-1/6 lg:translate-x-0">
                    <div data-aos="fade-left">
                      <GatsbyImage
                        image={getImage(randomizedCultureImage)}
                        alt="Making the world a better place."
                        className="min-h-450px sm:min-h-570px lg:min-h-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        {/* <section className="pt-clamp-53-72 pb-clamp-47-35">
          <div className="container">
            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12">
              <div className="col-span-5 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={handshakeLottie} />
                </div>
                <h3 data-aos="stagger" className="text-title mt-clamp-8-15">
                  <span>Our</span> <span>relationships</span> <span>are</span> <span>our</span>{" "}
                  <span className="text-teal">most</span> <span className="text-teal">valuable</span>{" "}
                  <span className="text-teal">resource.</span>
                </h3>
                <p data-aos="fade-right" className="mt-clamp-9-12">
                  35 years working with brands of all shapes and sizes has taught us that the best work is done with our
                  clients—not for them. We’ve seen the power of partnership firsthand, and we can’t imagine working any
                  other way.
                </p>
              </div>
            </div>
            <div className="grid-cols-12 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-9 col-start-4 lg:col-start-4 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={flowLottie} />
                </div>
                <h3 data-aos="stagger" className="text-title mt-clamp-8-15">
                  <span>Listen,</span> <span>think,</span> <span>do&mdash;</span>
                  <div className="inline sm:block"></div> <span className="text-teal">in</span>{" "}
                  <span className="text-teal">that</span> <span className="text-teal">order.</span>
                </h3>
                <p data-aos="fade-left" className="mt-clamp-9-12">
                  We’re here to share our expertise and get things done. But first and foremost, we’re here to listen.
                  We’ll dive into your story, your goals, and your vision, then provide thoughtful recommendations that
                  will help move your business forward.
                </p>
              </div>
            </div>
            <div className="grid-cols-6 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-5 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={lightbulbLottie} />
                </div>
                <h3 data-aos="stagger" className="text-title mt-clamp-8-15">
                  <span>Every</span> <span>idea</span> <span>can</span>
                  <br></br> <span className="text-teal">be</span> <span className="text-teal">a</span>{" "}
                  <span className="text-teal">big</span> <span className="text-teal">idea.</span>
                </h3>
                <p data-aos="fade-right" className="mt-clamp-9-12">
                  We're always looking to make great things happen, whether it’s a routine request or a complete
                  rebrand. We dig for insights, collaborate creatively, and push ourselves to deliver inspired work and
                  create exceptional outcomes that surprise and delight.
                </p>
              </div>
            </div>
            <div className="grid-cols-12 mx-auto sm:grid lg:grid-cols-10 lg:w-10/12 mt-clamp-22-25">
              <div className="col-span-9 col-start-4 lg:col-start-4 lg:col-span-7">
                <div data-aos="fade-up">
                  <Lottie animation={cutLottie} />
                </div>
                <h3 data-aos="stagger" className="text-title mt-clamp-8-15">
                  <span>If</span> <span>it's</span> <span>worth</span> <span>doing,</span> <span>it's</span>{" "}
                  <span className="text-teal">worth</span> <span className="text-teal">doing</span>{" "}
                  <span className="text-teal">right.</span>
                </h3>
                <p data-aos="fade-left" className="mt-clamp-9-12">
                  We take the time to craft creative, well-informed solutions that come headfirst for your challenges,
                  speak directly to your audiences, and empower your business with a story as unique as your brand.
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
      </main>
    </Layout>
  )
}

export default CulturePage

export const cultureQuery = graphql`
  query CulturePageQuery {
    chrisHImage: file(relativePath: { eq: "culture/chris-h.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    laurenDImage: file(relativePath: { eq: "culture/lauren-d.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    chrisMImage: file(relativePath: { eq: "culture/chris-m.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bakedGoodsImage: file(relativePath: { eq: "culture/baked-goods.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    chrisMOfficeImage: file(relativePath: { eq: "culture/chris-m-office.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    katieDImage: file(relativePath: { eq: "culture/katie-d.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    outsideImage: file(relativePath: { eq: "culture/outside.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    clutchImage: file(relativePath: { eq: "certificates/clutch.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
