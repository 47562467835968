import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Listbox } from "@headlessui/react"
import { settingsContext } from "@context/provider"
import ArrowDown from "@svg/ui/arrow-down.svg"

export const DepartmentDropdownSelector = ({ departments, department, setCurrentIndex }) => {
  const [, dispatchSettings] = useContext(settingsContext)

  const changeHandler = (index) => {
    dispatchSettings({ type: "SET_PERSON_DETAIL_MEMBER", payload: {} })
    dispatchSettings({ type: "SET_PERSON_DETAIL_DISPLAY", payload: false })
    setCurrentIndex(index)
  }

  return (
    <Listbox value={department} onChange={changeHandler}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="bg-purple w-full flex items-center justify-between px-7 py-8 text-white">
              <span className="font-bold">{department.name}</span>
              <span
                className={`flex items-center h-full fill-current text-white ${open && "transform rotate-180"}`}
                style={{ width: "12px" }}
              >
                <ArrowDown />
              </span>
            </Listbox.Button>
            <Listbox.Options
              as="div"
              className="bg-purple absolute inset-x-0 z-10 text-white"
              style={{ marginTop: "-1px" }}
            >
              <div className="grid grid-cols-1 gap-y-2 px-7 pb-8">
                {departments.edges.map((department, index) => (
                  <Listbox.Option as="span" key={index} value={index} className="cursor-pointer">
                    {department.node.name}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </div>
        </>
      )}
    </Listbox>
  )
}

DepartmentDropdownSelector.propTypes = {
  departments: PropTypes.object.isRequired,
  department: PropTypes.object.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
}
