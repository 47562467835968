import React, { useContext } from "react"

import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { settingsContext } from "@context/provider"
import { DepartmentDropdownSelector } from "./DepartmentDropdownSelector"
import { Detail } from "./Detail"
import { useTeam } from "./useTeam"

export const Team = ({ queryParam }) => {
  const { departments, department, currentIndex, setCurrentIndex } = useTeam(queryParam)
  const [settings, dispatchSettings] = useContext(settingsContext)
  const { personDetail } = settings

  const setDepartment = (index) => {
    dispatchSettings({ type: "SET_PERSON_DETAIL_MEMBER", payload: {} })
    dispatchSettings({ type: "SET_PERSON_DETAIL_DISPLAY", payload: false })
    setCurrentIndex(index)
  }

  return (
    <div>
      <div className="container mt-12">
        <div className="sm:hidden">
          <DepartmentDropdownSelector
            departments={departments}
            department={department}
            setCurrentIndex={setCurrentIndex}
          />
        </div>
        <div className="flex-wrap hidden mx-auto sm:flex gap-x-16 gap-y-7 lg:w-10/12">
          {departments.edges.map((department, index) => {
            return (
              <button key={index} onClick={() => setDepartment(index)} className="cursor-pointer">
                <span
                  className={`font-bold block ${
                    index === currentIndex
                      ? "text-purple border-b-2 border-purple"
                      : "border-b-2 border-transparent hover:text-purple"
                  }`}
                >
                  {department.node.name}
                </span>
              </button>
            )
          })}
        </div>
      </div>
      <div className={`transition-all duration-300 ${personDetail.display && "mt-20 md:mt-0"}`}>
        <Detail />
        <div className={`container ${personDetail.display ? "mt-20 pointer-events-none" : "pointer-events-auto"}`}>
          <div className="relative mx-auto lg:w-10/12">
            <div className="relative mt-clamp-18-25">
              <div className="relative bg-purple-light before:bg-purple-light after:bg-purple-light extend">
                <Carousel className="team-carousel">
                  <CursorMarker>
                    <CursorMarker.Default className="bg-purple after:text-white" />
                  </CursorMarker>
                  <Carousel.Swiper key={department.slug} spaceBetween={16} centeredSlides={false}>
                    {department.members.nodes.map((member, index) => {
                      return (
                        <Carousel.Slide key={member.id} style={{ width: "auto" }}>
                          <Carousel.Team index={index} member={member} />
                        </Carousel.Slide>
                      )
                    })}
                  </Carousel.Swiper>
                </Carousel>
              </div>
              <div className="container absolute inset-x-0 top-0">
                <div className="aspect-w-1436 aspect-h-120 extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-clamp-32-45 bg-purple-light"></div>
    </div>
  )
}
